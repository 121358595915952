import * as Sentry from '@sentry/nextjs'
import { useEffect, useState } from 'react'

import { useProductsLazyQuery, FrequencyUnit, ProductPrice } from 'modules/api'

import { BillingCycleKey } from '../../types'
import { getCheckoutRedirectUrl } from './utils'

type UseUpsellProArgs = {
  workspaceId?: string
  canManageWorkspace: boolean
  selectedBillingCycleKey: BillingCycleKey
}

type ProductPrices = Record<BillingCycleKey, ProductPrice | undefined>

class SentryHandledError extends Error {}

export const useUpsellPro = ({
  workspaceId,
  canManageWorkspace,
  selectedBillingCycleKey,
}: UseUpsellProArgs) => {
  const [getProducts] = useProductsLazyQuery()
  const [productPricesPro, setProductPricesPro] =
    useState<ProductPrices | null>()
  const [productPricesPlus, setProductPricesPlus] =
    useState<ProductPrices | null>()

  const selectedProductPrices =
    selectedBillingCycleKey === 'monthly'
      ? { pro: productPricesPro?.monthly, plus: productPricesPlus?.monthly }
      : selectedBillingCycleKey === 'yearly'
      ? { pro: productPricesPro?.yearly, plus: productPricesPlus?.yearly }
      : null

  const checkoutUrls = workspaceId
    ? {
        pro: getCheckoutRedirectUrl(workspaceId, [
          selectedProductPrices?.pro?.id || '',
        ]),
        plus: getCheckoutRedirectUrl(workspaceId, [
          selectedProductPrices?.plus?.id || '',
        ]),
      }
    : null

  // Pro pricing  annual discount
  const annualProPrice = productPricesPro?.yearly?.price || 0
  const monthlyProPrice = productPricesPro?.monthly?.price || 0
  const monthlyProPriceAnnualized = Math.round(monthlyProPrice * 12)
  const annualProDiscountPercentage =
    (monthlyProPriceAnnualized - annualProPrice) / monthlyProPriceAnnualized

  // Plus pricing annual discount
  const annualPlusPrice = productPricesPlus?.yearly?.price || 0
  const monthlyPlusPrice = productPricesPlus?.monthly?.price || 0
  const monthlyPlusPriceAnnualized = Math.round(monthlyPlusPrice * 12)
  const annualPlusDiscountPercentage =
    (monthlyPlusPriceAnnualized - annualPlusPrice) / monthlyPlusPriceAnnualized

  useEffect(() => {
    setProductPricesPro(null)

    if (!workspaceId) {
      return
    }

    // TODO - If the workspace already has Gamma Pro, we should
    //        get the productPrice object from the subscription
    // https://linear.app/gamma-app/issue/G-4825/add-means-to-retrieve-the-current-workspaces-subscription-details
    getProducts({
      variables: { workspaceId },
    })
      .then((res) => {
        if (res.error) {
          throw new Error('GraphQL error: ' + JSON.stringify(res.error))
        }

        const products = res.data?.products
        if (!products || !products.length) {
          Sentry.captureException('[getProducts] No products returned', {
            extra: {
              data: res.data,
            },
          })
          throw new SentryHandledError('[getProducts] No products returned')
        }

        const proProduct = products.find((p) => p.key === 'pro')
        const proMonthlyProductPrice = proProduct?.prices?.find(
          (p) => p.frequencyUnit === FrequencyUnit.Month
        )
        const proYearlyProductPrice = proProduct?.prices?.find(
          (p) => p.frequencyUnit === FrequencyUnit.Year
        )
        setProductPricesPro({
          monthly: proMonthlyProductPrice,
          yearly: proYearlyProductPrice,
        })

        const plusProduct = products.find((p) => p.key === 'plus')
        const plusMonthlyProductPrice = plusProduct?.prices?.find(
          (p) => p.frequencyUnit === FrequencyUnit.Month
        )
        const plusYearlyProductPrice = plusProduct?.prices?.find(
          (p) => p.frequencyUnit === FrequencyUnit.Year
        )
        setProductPricesPlus({
          monthly: plusMonthlyProductPrice,
          yearly: plusYearlyProductPrice,
        })
      })
      .catch((err) => {
        if (err instanceof SentryHandledError) {
          // Already handled
          return
        }
        console.error('[getProducts] err', err)
      })
  }, [workspaceId, getProducts, canManageWorkspace])

  return {
    selectedProductPrices,
    annualProDiscountPercentage,
    annualPlusDiscountPercentage,
    checkoutUrls,
    productPricesPlus,
    productPricesPro,
  }
}
