import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { faCircleCheck as faCircleCheckDuotone } from '@fortawesome/pro-duotone-svg-icons'
import {
  faHeart,
  faLock,
  faMessageLines,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useCreditStatus } from 'modules/credits/hooks'
import { useFeatureFlag } from 'modules/featureFlags'
import { withGoogleTagManager } from 'modules/googleTagManager'
import { sendGTMPurchaseEvent } from 'modules/googleTagManager/events'
import { GAMMA_PROPER_NOUN, PRO_PROPER_NOUN } from 'modules/i18n/properNouns'
import { useMonetizationContext } from 'modules/monetization/context'
import { BillingCycleKey, ProductKey } from 'modules/monetization/types'
import {
  getCurrencyDivisor,
  getProductForWorkspace,
  getProOrPlusProductPrice,
  getSubscriptionPriceAndInterval,
} from 'modules/monetization/utils'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { useCanManageWorkspace, useUserContext } from 'modules/user/context'

import { PURCHASE_COMPLETE_MESSAGE } from '../../constants'
import { BillingCycleSwitcher } from './BillingCycleSwitcher'
import { getProductDetails } from './constants'
import { ProductDetails } from './ProductDetails'
import { useUpsellPro } from './useUpsellPro'
import { getCustomerPortalURL, popupCenter } from './utils'

const USER_POLLING_INTERVAL = 1000

export const UpsellModalPro = ({
  insufficientCreditsWarning,
  isUpsellModalOpen,
  closeUpsellModal,
}: {
  insufficientCreditsWarning: boolean
  isUpsellModalOpen: boolean
  closeUpsellModal: () => void
}) => {
  const analytics = useAnalytics()
  const upsellUXEnabled_Plus = useFeatureFlag('upsellUXEnabled_Plus')
  const monthlyBillingEnabled = useFeatureFlag('monthlyBillingEnabled')
  const plusCreditsEnabled = useFeatureFlag('plus-credits')
  const canManageWorkspace = useCanManageWorkspace()
  const { subscription, product } = useMonetizationContext()
  const { currentWorkspace, user, refetch } = useUserContext()

  const { frequency } = getSubscriptionPriceAndInterval(subscription)
  const defaultAnnual = useFeatureFlag('defaultAnnualBilling')
  const [selectedBillingCycleKey, setSelectedBillingCycleKey] =
    useState<BillingCycleKey>(defaultAnnual ? 'yearly' : 'monthly')

  // If the user is already paying for monthly, show monthly options
  useEffect(() => {
    if (!frequency) return
    const cycle = frequency === 'month' ? 'monthly' : 'yearly'
    setSelectedBillingCycleKey(cycle)
  }, [frequency])

  const [purchaseComplete, setPurchaseComplete] = useState(false)
  const hasTrackedModalView = useRef(false)
  const currentProduct = getProductForWorkspace(currentWorkspace)

  const workspaceId = currentWorkspace?.id

  const {
    selectedProductPrices,
    annualProDiscountPercentage,
    annualPlusDiscountPercentage,
    checkoutUrls,
    productPricesPlus,
    productPricesPro,
  } = useUpsellPro({
    workspaceId,
    canManageWorkspace,
    selectedBillingCycleKey,
  })

  const showPlusUX = upsellUXEnabled_Plus || product === 'plus'
  const canCreateNewSubscription = canManageWorkspace && checkoutUrls
  const canManageExistingSubscription =
    product && subscription?.createdByUser?.id === user?.id
  const upgradeDisabled =
    !canCreateNewSubscription && !canManageExistingSubscription
  const { credits } = useCreditStatus()

  const currentSubscriptionProductPrice = getProOrPlusProductPrice(subscription)

  const showBillingCycleSwitcher =
    // TODO - Figure out how to handle changing monthly<>yearly billing
    // https://linear.app/gamma-app/issue/G-4856/allow-users-to-switch-from-monthly-to-annual-and-vice-versa
    !product && monthlyBillingEnabled

  const showContactAdminCallout = !canManageWorkspace

  useEffect(() => {
    if (product || !purchaseComplete) return

    // Poll for the workspace subscription to be updated
    // once a purchase is complete
    const interval = setInterval(() => {
      refetch?.()
    }, USER_POLLING_INTERVAL)

    refetch?.()

    return () => {
      clearInterval(interval)
    }
  }, [purchaseComplete, product, refetch])

  useEffect(() => {
    if (!isUpsellModalOpen) {
      setPurchaseComplete(false)
    }
  }, [isUpsellModalOpen])

  useEffect(() => {
    if (hasTrackedModalView.current) return
    if (
      product ||
      !canManageWorkspace ||
      !isUpsellModalOpen ||
      !selectedProductPrices
    ) {
      return
    }

    hasTrackedModalView.current = true
    analytics?.track(SegmentEvents.GAMMA_PRO_UPSELL_PRODUCTS_VIEWED, {
      price: selectedProductPrices.pro?.price,
      price_plus: selectedProductPrices?.plus?.price,
      productPricesPlus,
      productPricesPro,
      currentProduct,
      products: ['pro', 'plus'],
      currency: selectedProductPrices.pro?.currency,
      frequency: selectedProductPrices.pro?.frequencyUnit,
      plus_credits: plusCreditsEnabled,
      default_annual: defaultAnnual,
    })
  }, [
    isUpsellModalOpen,
    product,
    currentProduct,
    canManageWorkspace,
    analytics,
    selectedProductPrices,
    plusCreditsEnabled,
    defaultAnnual,
    productPricesPlus,
    productPricesPro,
  ])

  const handleSelectedBillingCycleKeyChange = useCallback(
    (billingCycle: BillingCycleKey) => {
      setSelectedBillingCycleKey(billingCycle)
      analytics?.track(SegmentEvents.GAMMA_PRO_BILLING_CYCLE_CLICK, {
        price: selectedProductPrices?.pro?.price,
        price_plus: selectedProductPrices?.plus?.price,
        products: ['pro', 'plus'],
        currency: selectedProductPrices?.pro?.currency,
        frequency: selectedProductPrices?.pro?.frequencyUnit,
        billingCycle,
        productPricesPlus,
        productPricesPro,
      })
    },
    [analytics, selectedProductPrices, productPricesPlus, productPricesPro]
  )

  const handleCheckoutClick = useCallback(
    (productKey: ProductKey) => () => {
      if (product) {
        window.open(getCustomerPortalURL(), '_blank')
        return
      }
      if (!checkoutUrls) return

      const checkoutUrl = checkoutUrls[productKey]
      const selectedProductPrice =
        productKey === 'plus'
          ? selectedProductPrices?.plus
          : selectedProductPrices?.pro

      analytics?.track(SegmentEvents.CHECKOUT_STARTED, {
        price: selectedProductPrice?.price,
        productPriceId: selectedProductPrice?.id,
        product: productKey,
        currency: selectedProductPrice?.currency,
        frequency: selectedProductPrice?.frequencyUnit,
        plus_credits: plusCreditsEnabled,
        productPricesPlus,
        productPricesPro,
      })

      const windowObject = popupCenter({
        url: checkoutUrl,
        title: 'Gamma',
        w: 1200,
        h: 1000,
      })

      window.addEventListener(
        'message',
        (event) => {
          if (event.data.type === PURCHASE_COMPLETE_MESSAGE) {
            setPurchaseComplete(true)
            setTimeout(() => {
              windowObject?.close()
            }, 100)
            const trackingData = {
              price: selectedProductPrice?.price,
              productPriceId: selectedProductPrice?.id,
              product: productKey,
              currency: selectedProductPrice?.currency,
              frequency: selectedProductPrice?.frequencyUnit,
              plus_credits: plusCreditsEnabled,
            }
            analytics?.track(SegmentEvents.PURCHASE_COMPLETE, trackingData)
            const gtmTrackingData = { ...trackingData }
            // GTM expects the currency to be in dollars
            if (selectedProductPrice?.price) {
              gtmTrackingData.price =
                selectedProductPrice.price /
                getCurrencyDivisor(selectedProductPrice.currency)
            }
            sendGTMPurchaseEvent(gtmTrackingData)
          }
        },
        false
      )
    },
    [
      checkoutUrls,
      analytics,
      selectedProductPrices,
      product,
      plusCreditsEnabled,
      productPricesPlus,
      productPricesPro,
    ]
  )

  return (
    <Modal
      isOpen={isUpsellModalOpen}
      trapFocus={false}
      size={purchaseComplete || insufficientCreditsWarning ? '3xl' : '5xl'}
      onClose={closeUpsellModal}
      closeOnEsc={!purchaseComplete}
      closeOnOverlayClick={!purchaseComplete}
    >
      <ModalOverlay />
      <ModalContent
        m={{ base: '0', lg: '4' }}
        bg="gray.100"
        borderRadius="xl"
        data-id="upsell-modal"
      >
        <ModalCloseButton />
        {!selectedProductPrices ? (
          <LoadingSkeleton />
        ) : purchaseComplete ? (
          <ModalContentPurchaseComplete
            isVerifying={!product}
            product={currentProduct}
            onClick={closeUpsellModal}
          />
        ) : (
          <ModalBody p={{ base: '4', lg: '6' }}>
            <Stack spacing={{ base: 4, lg: '6' }}>
              <Stack flex={1} gap={1.5}>
                {insufficientCreditsWarning && (
                  <Text
                    color="red.600"
                    fontSize={{ base: 'lg', lg: 'xl' }}
                    fontWeight="600"
                    textAlign="center"
                  >
                    <Trans>Insufficient credits</Trans>
                  </Text>
                )}
                <Heading
                  fontFamily="p22-mackinac-pro"
                  letterSpacing="tight"
                  size="xl"
                  fontWeight={'700'}
                  textAlign="center"
                >
                  {insufficientCreditsWarning ? (
                    <Trans>Upgrade to {PRO_PROPER_NOUN} for unlimited AI</Trans>
                  ) : (
                    <Trans>Plans and pricing</Trans>
                  )}
                </Heading>

                <Text
                  fontSize={{ base: 'md', lg: 'lg' }}
                  textAlign="center"
                  color="gray.700"
                >
                  {insufficientCreditsWarning && (
                    <Trans>You currently have {credits} credits.</Trans>
                  )}
                </Text>
              </Stack>

              {showContactAdminCallout && <ContactAdminCallout />}

              {showBillingCycleSwitcher && (
                <BillingCycleSwitcher
                  selectedBillingCycleKey={selectedBillingCycleKey}
                  setSelectedBillingCycleKey={
                    handleSelectedBillingCycleKeyChange
                  }
                />
              )}

              <Flex
                rounded="md"
                direction={{ base: 'column-reverse', lg: 'row' }}
                gap={{ base: '3', lg: '4' }}
              >
                {!insufficientCreditsWarning && (
                  <ProductDetails
                    productKey="free"
                    // Passing in Pro product so that we get correct symbol
                    // on details page for the Free product
                    productPrice={selectedProductPrices.pro}
                    isCurrentProduct={!product}
                  />
                )}
                {showPlusUX && (
                  <ProductDetails
                    productKey="plus"
                    productPrice={
                      product === 'plus'
                        ? currentSubscriptionProductPrice
                        : selectedProductPrices.plus
                    }
                    annualDiscount={annualPlusDiscountPercentage}
                    isUpgrade={!product}
                    isCurrentProduct={product === 'plus'}
                    upgradeDisabled={upgradeDisabled || product === 'plus'}
                    onClick={handleCheckoutClick('plus')}
                  />
                )}
                <ProductDetails
                  productKey="pro"
                  productPrice={
                    product === 'pro'
                      ? currentSubscriptionProductPrice
                      : selectedProductPrices.pro
                  }
                  annualDiscount={annualProDiscountPercentage}
                  isCurrentProduct={product === 'pro'}
                  isUpgrade={product ? product !== 'pro' : undefined}
                  upgradeDisabled={upgradeDisabled || product === 'pro'}
                  onClick={handleCheckoutClick('pro')}
                />
              </Flex>

              <PricingFooter />
            </Stack>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
}

const LoadingSkeleton = () => {
  return (
    <ModalBody>
      <Stack w="100%" px={10} pt={10}>
        <Skeleton height="60px" />
        <Skeleton height="30px" />
      </Stack>
      <Flex mt="8" rounded="xl" px={10} gap="8">
        <Stack w="100%" spacing={4}>
          <Skeleton height="300px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
        <Box
          w={{ base: 'unset', lg: '1px' }}
          minH="0"
          h={{ base: '1px', lg: 'unset' }}
          bg={'gray.100'}
        />
        <Stack w="100%" spacing={4}>
          <Skeleton height="300px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      </Flex>
    </ModalBody>
  )
}

const ModalContentPurchaseComplete = withGoogleTagManager(
  ({
    isVerifying = false,
    product,
    onClick,
  }: {
    isVerifying: boolean
    product?: ProductKey | null
    onClick?: () => void
  }) => {
    const productName = product ? getProductDetails()[product].name : ''
    return (
      <ModalBody>
        {isVerifying ? (
          <VStack py={12} spacing={4}>
            <Spinner size="lg" />
            <Heading
              fontFamily="p22-mackinac-pro"
              letterSpacing="tight"
              size="lg"
              fontWeight="semibold"
            >
              <Trans>Purchase successful</Trans>
            </Heading>
            <Text>
              <Trans>Just a sec while we set up your subscription...</Trans>
            </Text>
          </VStack>
        ) : (
          <VStack py={8} spacing={10}>
            <Box color="green.500">
              <FontAwesomeIcon icon={faCircleCheckDuotone} size="4x" />
            </Box>
            <Heading
              fontFamily="p22-mackinac-pro"
              letterSpacing="tight"
              size="lg"
              fontWeight="semibold"
            >
              <Trans>All done! Your subscription is now active.</Trans>
            </Heading>
            <Button size="lg" variant="solid" onClick={onClick}>
              <Trans>
                Start using {GAMMA_PROPER_NOUN} {productName}
              </Trans>
            </Button>
          </VStack>
        )}
      </ModalBody>
    )
  }
)

const ContactAdminCallout = () => {
  return (
    <Alert status="warning">
      <AlertIcon />
      <Stack spacing="0.5">
        <AlertTitle fontSize="lg">
          <Trans>Only workspace admins can upgrade</Trans>
        </AlertTitle>
        <AlertDescription>
          <Trans>
            Ask your admin to upgrade. You can find a list of your admins in
            workspace settings.
          </Trans>
        </AlertDescription>
      </Stack>
    </Alert>
  )
}

const PricingFooter = () => {
  return (
    <Stack spacing="4">
      <Text fontSize="sm" color="gray.600" textAlign="center">
        <Trans>
          <FontAwesomeIcon icon={faLock} fixedWidth /> Secure checkout
        </Trans>{' '}
        ·{' '}
        <Trans>
          <FontAwesomeIcon icon={faMessageLines} fixedWidth /> Priority customer
          support
        </Trans>{' '}
        ·{' '}
        <Trans>
          <FontAwesomeIcon icon={faHeart} fixedWidth /> Built with love
        </Trans>
      </Text>

      <Text fontSize="sm" color="gray.600" textAlign="center">
        <Trans>
          <Link
            href="https://help.gamma.app/en/articles/8077107-i-want-to-know-more-about-upgrading-my-workspace-to-gamma-s-paid-plans"
            isExternal
            textDecoration={'underline'}
            color="gray.800"
            textAlign="center"
          >
            Learn more about our paid plans
          </Link>
        </Trans>
      </Text>
    </Stack>
  )
}
