import { Box } from '@chakra-ui/react'
import { faSparkle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t, Trans } from '@lingui/macro'

import {
  MAX_CARDS_FREE,
  MAX_CARDS_PLUS,
  MAX_CARDS_PRO,
} from 'modules/ai/generator/hooks/useMaxCards'
import { featureFlags } from 'modules/featureFlags'
import {
  FREE_PROPER_NOUN,
  GAMMA_ARTIFACT_PROPER_NOUN_PLURAL,
  GAMMA_PROPER_NOUN,
  PLUS_PROPER_NOUN,
  PRO_PROPER_NOUN,
} from 'modules/i18n/properNouns'

import {
  BillingCycleDetail,
  BillingCycleKey,
  ProductDetail,
  ProductKey,
} from '../../types'

type ProductDetails = Record<ProductKey, ProductDetail>

export const getProductDetails = (): ProductDetails => {
  const passwordProtectedDoc = featureFlags.get('passwordProtectedDoc')
  return {
    free: {
      name: FREE_PROPER_NOUN,
      description: t`For casual users just getting started`,
      buttonVariant: 'plain',
      backgroundColor: 'gray.50',
      featureHeading: t`Plan includes:`,
      features: [
        {
          label: t`400 AI credits at signup`,
          icon: <FontAwesomeIcon icon={faSparkle} fixedWidth />,
        },
        { label: t`Generate up to ${MAX_CARDS_FREE} cards` },
        { label: t`Unlimited ${GAMMA_ARTIFACT_PROPER_NOUN_PLURAL} and users` },
        { label: t`PDF export (${GAMMA_PROPER_NOUN} branded)` },
        { label: t`PPT export (${GAMMA_PROPER_NOUN} branded)` },
      ],
    },
    plus: {
      name: PLUS_PROPER_NOUN,
      description: t`For personal use. Get unlimited AI and remove ${GAMMA_PROPER_NOUN} branding.`,
      buttonVariant: 'outline',
      titleColor: 'trueblue.600',
      descriptionColor: 'trueblue.700',
      backgroundColor: 'trueblue.50',
      featureHeading: t`Plan includes:`,
      features: [
        {
          label: (
            <Box
              as="span"
              bg="var(--chakra-colors-gradient-blue-to-orange)"
              color="transparent"
              backgroundClip="text"
            >
              <Trans>Unlimited AI usage</Trans>
            </Box>
          ),
          icon: (
            <Box color="orchid.500">
              <FontAwesomeIcon icon={faSparkle} fixedWidth />
            </Box>
          ),
        },
        { label: t`Generate up to ${MAX_CARDS_PLUS} cards` },
        { label: t`Remove "Made with ${GAMMA_PROPER_NOUN}" badge` },
        { label: t`PDF export` },
        { label: t`PPT export` },
        { label: t`Priority support` },
        { label: t`Everything in ${FREE_PROPER_NOUN}` },
      ],
      dontChurnMessage: t`Don't lose your AI credits.`,
    },
    pro: {
      name: PRO_PROPER_NOUN,
      description: t`For professionals. Our most advanced AI editing and sharing features.`,
      titleColor: 'orchid.600',
      descriptionColor: 'orchid.700',
      buttonVariant: 'solid',
      backgroundGradient:
        'linear-gradient(134deg, rgba(234, 231, 255, 1) 10%, rgba(181,57,224,0.15) 60%, rgba(223,122,108,0.15) 100%)',
      backgroundImage: `url(https://cdn.gamma.app/zc87vhr30n8uf3n/bf962e249e17461184fafb003ac42263/original/stars-3.png)`,
      borderColor: 'var(--chakra-colors-gradient-blue-to-orange)',
      featureHeading: t`Plan includes:`,
      features: [
        {
          label: (
            <Box
              as="span"
              bg="var(--chakra-colors-gradient-blue-to-orange)"
              color="transparent"
              backgroundClip="text"
            >
              <Trans>Unlimited AI usage</Trans>
            </Box>
          ),
          icon: (
            <Box color="orchid.500">
              <FontAwesomeIcon icon={faSparkle} fixedWidth />
            </Box>
          ),
        },
        { label: t`Generate up to ${MAX_CARDS_PRO} cards` },
        {
          label: t`More powerful models`,
          infoTip: t`Our most advanced image generation and tech models for longer inputs, more detailed prompts, and larger images.`,
        },
        { label: t`Remove "Made with ${GAMMA_PROPER_NOUN}" badge` },
        {
          label: t`Publish to custom domains`,
          infoTip: t`Publish your ${GAMMA_PROPER_NOUN} site to a custom domain`,
        },
        { label: t`Custom fonts` },
        { label: t`Detailed analytics` },
        ...(passwordProtectedDoc ? [{ label: t`Password protection` }] : []),
        { label: t`Everything in ${PLUS_PROPER_NOUN}` },
      ],
      badgeLabel: t`Popular`,
      dontChurnMessage: t`Don't lose unlimited AI.`,
    },
  }
}

export const getBillingCycleDetails = (): Record<
  BillingCycleKey,
  BillingCycleDetail
> => {
  return {
    monthly: {
      name: t`Monthly billing`,
      description: t`Billed monthly`,
    },
    yearly: {
      name: t`Annual billing`,
      description: t`When billed annually`,
    },
  }
}
