import { useCanUseProductFeature } from 'modules/monetization/hooks/productFeatures'
import { useAppSelector } from 'modules/redux'

import { selectGeneratorSetting } from '../reducer'

export const MAX_CARDS_FREE = 10
export const MAX_CARDS_PLUS = 15
export const MAX_CARDS_PRO = 30 // The max for freeform mode, and generating outlines
const MAX_CARDS_PRO_STRUCTURED = 40 // The "true" max if you add more cards in structured mode. We cut off after this.

export const useMaxCards = () => {
  const hasPlusCardCount = useCanUseProductFeature('ai_card_count_plus')
  const hasProCardCount = useCanUseProductFeature('ai_card_count_pro')
  const editorMode = useAppSelector(selectGeneratorSetting('editorMode'))
  const maxCards = hasProCardCount
    ? editorMode === 'structured'
      ? MAX_CARDS_PRO_STRUCTURED
      : MAX_CARDS_PRO
    : hasPlusCardCount
    ? MAX_CARDS_PLUS
    : MAX_CARDS_FREE
  return {
    maxCards,
    canUpgrade: !hasProCardCount,
  }
}
