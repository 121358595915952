import {
  Box,
  Center,
  chakra,
  Flex,
  FlexProps,
  useColorModeValue as mode,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { Dispatch, SetStateAction } from 'react'

import { getBillingCycleDetails } from 'modules/monetization/components/UpsellModal/constants'
import { BillingCycleKey } from 'modules/monetization/types'
import { isMobileDevice } from 'utils/deviceDetection'

const ActiveIndicatorImpl = chakra('div', {
  baseStyle: {
    w: 'full',
    h: 'full',
    rounded: 'full',
    pos: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
  },
})

export const ActiveIndicator = motion(ActiveIndicatorImpl)

export const RadioButton = (props) => {
  const { getInputProps, getCheckboxProps, getLabelProps, state } =
    useRadio(props)

  return (
    <Box as="label" pos="relative" {...getLabelProps()}>
      <input {...getInputProps()} />
      <Center
        {...getCheckboxProps()}
        cursor="pointer"
        pos="relative"
        zIndex={1}
        h="10"
        px="4"
        textAlign="center"
        transition="all 0.2s"
        minW={{ base: 'none', md: '10rem' }}
        fontWeight="medium"
        _checked={{
          color: mode('trueblue.600', 'trueblue.200'),
          bg: mode('trueblue.50', 'trueblue.900'),
          boxShadow: 'none',
          rounded: 'full',
          fontWeight: 'bold',
        }}
      >
        {props.children}
      </Center>
      {state.isChecked && (
        <ActiveIndicator
          bg={mode('white', 'gray.600')}
          layoutId="highlight"
          transition={{
            duration: '0.1',
          }}
        />
      )}
    </Box>
  )
}

type BillingCycleSwitcherProps = FlexProps & {
  selectedBillingCycleKey: BillingCycleKey
  setSelectedBillingCycleKey: Dispatch<SetStateAction<BillingCycleKey>>
}

export const BillingCycleSwitcher = ({
  selectedBillingCycleKey,
  setSelectedBillingCycleKey,
  ...rest
}: BillingCycleSwitcherProps) => {
  const { getRadioProps, getRootProps } = useRadioGroup({
    defaultValue: selectedBillingCycleKey,
    onChange: (e: BillingCycleKey) => {
      setSelectedBillingCycleKey(e)
    },
  })
  const isMobile = isMobileDevice()

  return (
    <Flex pos="relative" justifyContent="center" alignItems="center">
      <Flex
        display="inline-flex"
        align="center"
        bg={mode('white', 'gray.700')}
        rounded="full"
        p="2"
        boxShadow="base"
        {...getRootProps(rest)}
      >
        <RadioButton
          {...getRadioProps({
            value: 'monthly',
          })}
        >
          {getBillingCycleDetails().monthly.name}
        </RadioButton>
        <RadioButton
          {...getRadioProps({
            value: 'yearly',
          })}
        >
          {getBillingCycleDetails().yearly.name}
        </RadioButton>
      </Flex>
    </Flex>
  )
}
