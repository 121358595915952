import { useCallback } from 'react'

import { useAppDispatch } from 'modules/redux'

import { setIsIntercomOpen } from './reducer'

export const useLaunchIntercom = () => {
  const dispatch = useAppDispatch()

  const launch = useCallback(() => {
    const Intercom = window.Intercom
    if (!Intercom) return

    Intercom('show')

    // We need to do this owing to https://github.com/gamma-app/gamma/blob/2db66fb0a2854a5bd0dfa990df88352c3c2b5c92/packages/client/src/modules/intercom/IntercomScript.tsx#L31-L35
    dispatch(setIsIntercomOpen({ isOpen: true }))
  }, [dispatch])

  return launch
}
